import { toast } from 'react-toastify';
import { call, put, takeLatest, all } from 'redux-saga/effects';

import * as AuthActions from '../auth/actions';
import * as PaymentLinkActions from './actions';
import api from '~/services/api';

export function* createPaymentLink({ payload }) {
  try {
    const {
      amount,
      installment,
      oneTimePayment,
      methods,
      name,
      discounts,
      daysToExpire,
      file,
      product,
      shippingFee,
      expiresIn,
      sellerName,
      pixel,
      chargeCustomerProcessingFee,
      splitRules,
    } = payload;

    const formData = new FormData();

    formData.append('amount', amount);
    formData.append('installment', installment || 1);
    formData.append('name', name);
    formData.append('daysToExpire', daysToExpire);
    formData.append('expiresIn', expiresIn || 1);
    formData.append('discounts', discounts);
    formData.append('file', file);
    formData.append('product', product);
    formData.append('shippingFee', shippingFee);
    formData.append('sellerName', sellerName);
    formData.append('pixel', pixel);
    formData.append('chargeCustomerProcessingFee', chargeCustomerProcessingFee);
    formData.append('oneTimePayment', oneTimePayment);

    methods.forEach((method) => {
      formData.append('methods[]', method);
    });
    splitRules.forEach((splitRule) => {
      formData.append('splitRules[]', JSON.stringify(splitRule));
    });

    yield call(api.post, '/payment_links', formData);

    toast.success('Link de pagamento criado com sucesso.');
    yield put(PaymentLinkActions.createPaymentLinkSuccess());
  } catch (err) {
    toast.error(
      'Ops... Parece que ocorreu algum erro. Verifique os dados e/ou tente novamente mais tarde.'
    );
    yield put(PaymentLinkActions.failure({ status: err?.response?.status }));
  }
}

export function* updatePaymentLink({ payload }) {
  try {
    const {
      amount,
      installment,
      methods,
      name,
      dueDate,
      discounts,
      shippingFee,
      expiresIn,
      sellerName,
      pixel,
      status,
      path,
      oneTimePayment,
    } = payload;

    yield call(api.put, `/payment_links/${path}`, {
      amount,
      installment,
      methods,
      name,
      dueDate,
      discounts,
      shippingFee,
      expiresIn,
      sellerName,
      pixel,
      status,
      oneTimePayment,
    });

    toast.success('Link de pagamento atualizado com sucesso.');
    yield put(PaymentLinkActions.updatePaymentLinkSuccess());
  } catch (err) {
    toast.error(
      'Ops... Parece que ocorreu algum erro. Verifique os dados e/ou tente novamente mais tarde.'
    );
    yield put(PaymentLinkActions.failure({ status: err?.response?.status }));
  }
}

export function* failure({ payload }) {
  const { status } = payload;

  if (status === 401) {
    yield put(AuthActions.authFailure({ status }));
  }
}

export default all([
  takeLatest('@paymentLink/CREATE_PAYMENT_LINK_REQUEST', createPaymentLink),
  takeLatest('@paymentLink/UPDATE_PAYMENT_LINK_REQUEST', updatePaymentLink),
  takeLatest('@paymentLink/FAILURE', failure),
]);
