export const createPaymentLinkRequest = ({
  amount,
  installment,
  methods,
  discounts,
  name,
  daysToExpire,
  file,
  product,
  shippingFee,
  expiresIn,
  sellerName,
  pixel,
  oneTimePayment,
  chargeCustomerProcessingFee,
  splitRules,
}) => ({
  type: '@paymentLink/CREATE_PAYMENT_LINK_REQUEST',
  payload: {
    amount,
    oneTimePayment,
    installment,
    methods,
    name,
    discounts,
    daysToExpire,
    file,
    product,
    shippingFee,
    expiresIn,
    sellerName,
    pixel,
    chargeCustomerProcessingFee,
    splitRules,
  },
});

export const createPaymentLinkSuccess = () => ({
  type: '@paymentLink/CREATE_PAYMENT_LINK_SUCCESS',
  payload: {},
});

export const updatePaymentLinkRequest = ({
  amount,
  installment,
  methods,
  name,
  dueDate,
  shippingFee,
  discounts,
  expiresIn,
  sellerName,
  pixel,
  status,
  path,
  oneTimePayment,
}) => ({
  type: '@paymentLink/UPDATE_PAYMENT_LINK_REQUEST',
  payload: {
    amount,
    installment,
    methods,
    name,
    dueDate,
    shippingFee,
    discounts,
    expiresIn,
    sellerName,
    pixel,
    status,
    path,
    oneTimePayment,
  },
});

export const updatePaymentLinkSuccess = () => ({
  type: '@paymentLink/UPDATE_PAYMENT_LINK_SUCCESS',
  payload: {},
});

export function failure({ status }) {
  return {
    type: '@paymentLink/FAILURE',
    payload: { status },
  };
}
